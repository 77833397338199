<template>
  <v-dialog v-model="visible" max-width="300">
    <v-card :color="color">
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn elevation="0" @click="reject()">NIE</v-btn>
        <v-btn elevation="0" @click="accept()">TAK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmationAlert',
  data: () => ({visible: false, title: '', message: '', color: 'primary', callback: () => false}),
  methods: {
    show(title, message, color, callback) {
      this.title = title;
      this.message = message;
      this.color = color;
      this.callback = callback;
      this.visible = true;
    },
    reject() {
      this.visible = false;
    },
    accept() {
      this.visible = false;
      this.callback();
    }
  }
};
</script>
