<template>
  <v-dialog v-model="visible" max-width="300">
    <v-card :color="color">
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn elevation="0" @click="visible = false">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Alert',
  data: () => ({visible: false, title: '', message: '', color: 'primary'}),
  methods: {
    show(title, message, color) {
      this.title = title;
      this.message = message;
      this.color = color;
      this.visible = true;
    }
  }
};
</script>
