<template>
  <v-container>

    <!-- create category -->
    <v-card outlined class="mb-3">
      <v-toolbar flat dense color="primary">
        <v-toolbar-title>Stwórz nową kategorię</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-text-field dense outlined v-model="newCategory.id" type="number" min="0" max="100" step="1"
                          label="Identyfikator kategorii" hint="0 - 100"/>
          </v-col>
          <v-col cols="6">
            <v-text-field dense outlined v-model="newCategory.name" counter="20" label="Nazwa kategorii"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-checkbox dense outlined v-model="newCategory.minIf" label="Wymagaj minimalnego roku urodzenia"/>
          </v-col>
          <v-col cols="4">
            <v-text-field dense outlined v-model="newCategory.minValue" type="number" min="0" max="100" step="1"
                          label="Minimalny rok urodzenia" hint="0 - 9999" :disabled="!newCategory.minIf"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-checkbox dense outlined v-model="newCategory.maxIf" label="Wymagaj maksymalnego roku urodzenia"/>
          </v-col>
          <v-col cols="4">
            <v-text-field dense outlined v-model="newCategory.maxValue" type="number" min="0" max="100" step="1"
                          label="Maksymalny rok urodzenia" hint="0 - 9999" :disabled="!newCategory.maxIf"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-checkbox dense outlined v-model="newCategory.genderIf" label="Wymagaj konkretnej płci"/>
          </v-col>
          <v-col cols="4">
            <v-select dense outlined v-model="newCategory.genderValue" type="number" min="0" max="100" step="1"
                      label="Płeć" :disabled="!newCategory.genderIf" :items="['kobiety', 'mężczyźni']"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-checkbox dense outlined v-model="newCategory.parentIf" label="Wymagaj konkretnego statusu rodzica"/>
          </v-col>
          <v-col cols="4">
            <v-select dense outlined v-model="newCategory.parentValue" type="number" min="0" max="100" step="1"
                      label="Status rodzica" :disabled="!newCategory.parentIf"
                      :items="['tylko rodzice', 'wszyscy oprócz rodziców']"/>
          </v-col>
        </v-row>
        <v-divider/>
        <v-toolbar-title class="mt-3 mb-3">Konkurencje</v-toolbar-title>
        <v-row>
          <v-col v-for="(event, index) in newCategory.events" :key="index" cols="4">
            <v-checkbox dense outlined v-model="newCategory.events[index]"
                        :label="$utils.events.getEventName(index)"/>
            {{ event ? '' : '' }}
          </v-col>
        </v-row>
        <v-divider class="mb-3"/>
        <v-btn color="primary" @click="createCategory()">DODAJ KATEGORIĘ</v-btn>
      </v-card-text>
    </v-card>

    <!-- categories -->
    <v-card outlined class="mb-3">
      <v-toolbar flat dense color="primary">
        <v-toolbar-title>Lista kategorii</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-data-table dense :headers="headers" :items="categories" item-key="category_id" :loading="loading"
                      :search="search">
          <!--suppress HtmlUnknownAttribute -->
          <template v-slot:item.events="{item}">
            <span v-for="(event, index) in item.events.split('')" :key="index">
            <v-icon v-if="event === '1'" color="primary" class="pa-1">
              {{ $utils.events.getEventIcon(index) }}
            </v-icon></span>
          </template>
          <!--suppress HtmlUnknownAttribute -->
          <template v-slot:item.options="{item}">
            <v-icon left @click="preDeleteCategory(item['category_id'])">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- info -->
    <v-card outlined class="mb-3" v-if="!loading">
      <v-toolbar flat dense color="primary">
        <v-toolbar-title>Edytuj podstawowe informacje i punktację</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field dense outlined v-model="id" counter="8" label="Identyfikator cyklu" disabled :value="id"/>
          </v-col>
          <v-col>
            <v-text-field dense outlined v-model="places" type="number" min="1" max="500" step="1"
                          label="Liczba miejsc punktujących" hint="1 - 500"/>
          </v-col>
        </v-row>
        <v-text-field dense outlined v-model="name" counter="60" label="Nazwa cyklu"/>
        <v-divider class="mb-6"/>
        <v-text-field v-for="(place, index) in points" :key="index" dense outlined
                      v-model="points[index]" :label="(index + 1) + '. miejsce'" type="number" min="1" max="10000"
                      :value="place"/>
        <v-btn color="primary" @click="editSeries()">ZAPISZ ZMIANY</v-btn>
      </v-card-text>
    </v-card>

    <!-- alerts -->
    <Alert ref="alert"/>
    <ConfirmationAlert ref="confirmation"/>

  </v-container>
</template>

<script>
import Alert from '../components/Alert';
import ConfirmationAlert from '../components/ConfirmationAlert';

export default {
  name: 'AdminRankingsSeriesEdit',
  components: {Alert, ConfirmationAlert},
  data: () => ({
    id: '', name: '', places: '', points: '', categories: [], loading: true, deleteId: null, search: '',
    headers: [
      {text: 'Nazwa', align: 'start', sortable: true, value: 'category_name'},
      {text: 'ID', align: 'start', sortable: true, value: 'category_id'},
      {text: 'Min rok', align: 'center', sortable: true, value: 'min_year'},
      {text: 'Max rok', align: 'center', sortable: true, value: 'max_year'},
      {text: 'Płeć', align: 'center', sortable: true, value: 'gender'},
      {text: 'Status rodzica', align: 'center', sortable: false, value: 'parent'},
      {text: 'Konkurencje', align: 'center', sortable: false, value: 'events'},
      {text: '', align: 'right', sortable: false, value: 'options'}
    ],
    newCategory: {
      id: 0, name: '', events: new Array(17).fill(false),
      minIf: false, minValue: 0, maxIf: false, maxValue: 9999,
      genderIf: false, genderValue: 'kobiety', parentIf: false, parentValue: 'tylko rodzice'
    },
    editCategory: {
      originalId: null, id: null, name: null, events: null,
      minIf: false, minValue: 0, maxIf: false, maxValue: 9999,
      genderIf: false, genderValue: null, parentIf: false, parentValue: null
    }
  }),
  created() {
    this.create();
  },
  methods: {
    create() {
      this.$http.post('/api/admin/categories/get', {id: this.$route.params.id}).then((res) => {
        this.id = res.data.series['series_id'];
        this.name = res.data.series['series_name'];
        this.places = res.data.series.points.length;
        this.points = res.data.series.points;
        this.categories = res.data.categories;
      }).catch((e) => {
        console.log(e);
      }).finally(() => {
        this.loading = false;
      });
    },
    editSeries() {
      let id = this.id, name = this.name, points = this.points.map((item) => parseInt(item));
      this.$http.post('/api/admin/series/update', {id, name, points}).then(() => {
        this.$refs.alert.show('OK', 'Pomyślnie zapisano zmiany.', 'success');
      }).catch((e) => {
        this.$refs.alert.show('Błąd', 'Zmiany nie zostały zapisane.', 'error');
        console.log(e);
      });
    },
    createCategory() {
      let id = this.id;
      let category = {
        category_id: parseInt(this.newCategory.id),
        category_name: this.newCategory.name,
        min_year: this.newCategory.minIf ? parseInt(this.newCategory.minValue) : null,
        max_year: this.newCategory.maxIf ? parseInt(this.newCategory.maxValue) : null,
        gender: this.newCategory.genderIf ? (this.newCategory.genderValue === 'kobiety' ? 'f' : 'm') : null,
        parent: this.newCategory.genderIf ? (this.newCategory.genderValue === 'tylko rodzice') : null,
        events: this.newCategory.events.map((item) => item ? '1' : '0').join('')
      };
      this.$http.post('/api/admin/categories/create', {id, category}).then(() => {
        this.$refs.alert.show('OK', 'Pomyślnie utworzono nową kategorię. ', 'success');
        this.create();
      }).catch((e) => {
        this.$refs.alert.show('Błąd', 'Nowa kategoria nie została utworzona.', 'error');
        console.log(e);
      });
    },
    preDeleteCategory(id) {
      this.deleteId = id;
      this.$refs.confirmation.show(id, 'Czy na pewno chcesz usunąć kategorię o podanym identyfikatorze?', 'error', this.deleteCategory);
    },
    deleteCategory() {
      const body = {id: this.$route.params.id, category_id: this.deleteId};
      this.$http.post('/api/admin/categories/delete', body).then(() => {
        this.$refs.alert.show('Sukces', 'Pomyślnie usunięto kategorię.', 'success');
        this.create();
      }).catch((e) => {
        this.$refs.alert.show('Błąd', 'Kategoria nie została usunięta.', 'error');
        console.log(e);
      });
    }
  },
  watch: {
    places: function (val) {
      while (this.points.length < val)
        this.points.push('1')
      while (this.points.length > val)
        this.points.pop();
    }
  }
};
</script>
